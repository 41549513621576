@font-face {
  font-family: 'Lucida Handwriting';
  src: url('/static/fonts/LucidaHandwriting-Italic.woff2') format('woff2'), url('/static/fonts/LucidaHandwriting-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
}

html {
  min-height: 100vh;
  background-image: url("./assets/background.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}

body {
  background-color: transparent;
}

.noteuse_wrapper{
  display: inline-block;
  background: #00AFF9 url(https://cbwconline.com/IMG/Codepen/Unplugged.png) center/cover no-repeat;
  height: 100vh;
  margin: 0;
  color: white;
}

.noteuse_h1 {
  margin: .8em 3rem;
  font: 4em Roboto;
}

.noteuse_p {
  display: inline-block;
  margin: .2em 3rem;
  font: 2em Roboto;
}
